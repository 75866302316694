module.exports = {
  hero: {
    title: "Brainstorming for desired perfect Usability",
    description:
      "Our design projects are fresh and simple and will benefit your business greatly. Learn more about our work!",
    bgImage: "img/app-hero-bg.jpg"
  },
  promo: {
    title: "Our company Foundation",
    description:
      "Following reasons show advantages of adding Brinf LLC to your business, our three pilar are:",
    items: [
      {
        title: "Secure Firts",
        description:
          "We don't negotiate the security of our customer.",
        icon: "ti-lock text-white"
      },
      {
        title: "Customer Oriented",
        description:
          "More efficient using cutting edge technology.",
        icon: "ti-user text-white"
      },
      {
        title: "Quantum Researchers",
        description:
          "From Data to information using Maths Model & ML.",
        icon: "ti-bar-chart-alt text-white"
      }
    ]
  },
  aboutUs: {
    title: {
      bold: "Use your android or ios device",
      normal: ""
    },
    description:
      "Following reasons show advantages of adding AgencyCo to your lead pages, demos and checkouts",
    items: [
      {
        title: "Clean Design",
        description:
          "Increase sales by showing true dynamics of your website",
        icon: "ti-vector text-white"
      },
      {
        title: "Secure Data",
        description:
          "Build your online store’s trust using Social Proof & Urgency",
        icon: "ti-lock text-white"
      },
      {
        title: "Retina Ready",
        description:
          "Realize importance of social proof in customer’s purchase decision",
        icon: "ti-eye text-white"
      }
    ]
  },
  price: {
    title: "Pricing Packages",
    description:
      "Uniquely maintain efficient expertise whereas cross functional deliverables. Continually leverage other's competitive mind share whereas enabled channels. ",
    packages: [
      {
        price: "$0",
        license: "Basic",
        isFeatured: true,
        features: [
          "15-minute called",
          "Security Advice",
          "Product Advice",
          "Recommendations"
        ]
      },
      {
        price: "Custom",
        license: "Cloud",
        isFeatured: false,
        features: [
          "Data Transfer",
          "SQL Database",
          "24/7 application up",
          "6 months technical support"
        ]
      },
      {
        price: "Custom",
        license: "OnPremise",
        isFeatured: false,
        features: [
          "Data Transfer",
          "SQL Database",
          "24/7 application up",
          "2 months technical support"
        ]
      }
    ]
  },
  testimonial: {
    title: "What customer say about us",
    description:
      'Rapidiously morph transparent internal or "organic" sources whereas resource sucking e-business. Conveniently innovate compelling internal.',
    comments: [
      {
        name: "Arabella Ora",
        company: "AgencyCo",
        image: "img/client-2.jpg",
        comment:
          "Completely build backend ROI whereas cross-media metrics. Collaboratively deploy customer directed web-readiness via global testing procedures. Appropriately reinvent distributed innovation without proactive catalysts for change. Distinctively."
      },
      {
        name: "John Charles",
        company: "ThemeTags",
        image: "img/client-1.jpg",
        comment:
          "If you can design one thing you can design everything with Front. Just believe it. Collaboratively repurpose performance based e-commerce without cost. It's beautiful and the coding is done quickly and seamlessly."
      },
      {
        name: "Jeremy Jere",
        company: "WorldCheck",
        image: "img/client-3.jpg",
        comment:
          "The template is really nice and offers quite a large set of options. It's beautiful and the coding is done. If you can design one thing you can design everything with Front. Just believe it."
      },
      {
        name: "Ciaran Kierce",
        company: "Brinf LLC",
        image: "img/client-4.jpg",
        comment:
          "I just wanted to share a quick note and let you know that you guys do a really good job. I’m glad I decided to work with you. It’s really great how easy your websites are to update and manage. I never have any problem at all."
      }
    ]
  },
  contact: {
    title: "Contact with us",
    description:
      "It's very easy to get in touch with us. Just use the contact form.",

    office: "Head Office",
    address: "New Jersey, USA",
    phone: "+1 857 323 2683",
    email: "hello@brinf.net"
  },
  trustedCompany: [
    "img/client-1-color.png",
    "img/client-6-color.png",
    "img/client-3-color.png",
    "img/client-4-color.png",
    "img/client-5-color.png"
  ],
  trustedCompanyGray: [
    "img/client-1-gray.png",
    "img/client-2-gray.png",
    "img/client-3-gray.png",
    "img/client-4-gray.png",
    "img/client-5-gray.png"
  ],

  aboutUsImages: {
    title: "AboutUs",
    description: "We used languages, frameworks, tools and products that is a \"standard\" in the most success company. we learn from the following company:",
    codeImages: [
      "img/image-14-1.png",
      "img/image-14-2.png"
    ]
  },

  trustedCompanyAboutUs: {
    title: "We used well tested technology",
    description: "We used languages, frameworks, tools and products that is a \"standard\" in the most success company. we learn from the following company:",
    companies: [
      "img/client-1-color.png",
      "img/client-2-color.png",
      "img/client-3-color.png",
      "img/client-4-color.png",
      "img/client-5-color.png",
      "img/client-6-color.png",
      "img/client-7-color.png"
    ]
  },
  teamMember: {
    members: [
      {
        name: "Edna Mason",
        designation: "Senior Designer",
        image: "img/team-4.jpg"
      },
      {
        name: "Arabella Ora",
        designation: "Senior Designer",
        image: "img/team-1.jpg"
      },
      {
        name: "John Charles",
        designation: "Senior Designer",
        image: "img/team-2.jpg"
      },
      {
        name: "Jeremy Jere",
        designation: "Senior Designer",
        image: "img/team-3.jpg"
      },
    ]
  },
  video: {
    link: "https://www.youtube.com/watch?v=9No-FiEInLA"
  }
};
