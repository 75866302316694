import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export const authenticateAnonymously = () => {
    return firebase.auth().signInAnonymously();
};

//Subscribe
export const createSubscribeList = (email, check) => {
    return db.collection('subscribeList')
        .add({
            email: email,
            source: 'website',
            check: check,
            created: firebase.firestore.FieldValue.serverTimestamp(),
        });
};


export const getSubscribeList = subscribeListIs => {
    return db.collection('subscribeList')
        .doc(subscribeListIs)
        .get();
};

//Contact Us
export const createContactUsList = (name, email, phone, company, message, check) => {
    return db.collection('contactUsList')
        .add({
            name: name,
            email: email,
            phone: phone,
            company: company,
            message: message,
            source: 'website',
            check: check,
            created: firebase.firestore.FieldValue.serverTimestamp(),

        });
};
