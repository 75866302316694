import React from "react";

class Feature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section id="services" className="feature-section ptb-100">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-4">
                <div className="download-img d-flex">
                  <img
                    src="img/about-ai-2.gif"
                    alt="about"
                  />
                </div>
              </div>
              <div className="col-md-7">
                <div className="feature-contents section-heading">
                  <h2>
                    Services
                  </h2>
                  <p>
                    Our services and product are well tested and backed by the best Engineer Team.
                  </p>

                  <div className="feature-content-wrap">
                    <ul className="nav nav-tabs feature-tab" data-tabs="tabs">
                      <li className="nav-item">
                        <a
                          className="nav-link active h6"
                          href="#tab6-1"
                          data-toggle="tab"
                        >
                          <span className="ti-palette"></span>
                          System Design
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link h6"
                          href="#tab6-2"
                          data-toggle="tab"
                        >
                          <span className="ti-vector"></span>
                          Development
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link h6"
                          href="#tab6-3"
                          data-toggle="tab"
                        >
                          <span className="ti-bar-chart"></span>
                            Stock Market
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link h6"
                          href="#tab6-4"
                          data-toggle="tab"
                        >
                          <span className="ti-lock"></span>
                          Security
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content feature-tab-content">
                      <div className="tab-pane active" id="tab6-1">
                        <ul className="list-unstyled">
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">IT Consulting in your current architecture.</p>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                  Scale from 100 user to "1 millions" Users.
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                  Microservices architecture planning.
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                  From Data-Center to Cloud Migration.
                                </p>
                              </div>
                            </div>
                          </li>
 
                        </ul>
                      </div>
                      <div className="tab-pane" id="tab6-2">
                        <div className="single-feature">
                          <div className="d-flex align-items-center mb-2">
                            <span className="ti-exchange-vertical rounded mr-3 icon icon-color-1"></span>
                            <h5 className="mb-0">Backend</h5>
                          </div>
                          <p>
                          We create a custom and high performace system using languages like Python, nodejs, Java with Sprint boot, C++ and tools like Messages queue and in-Memory database.
                          </p>
                        </div>
                        <div className="single-feature mb-4">
                          <div className="d-flex align-items-center mb-2">
                            <span className="ti-ruler-alt-2 rounded mr-3 icon icon-color-2"></span>
                            <h5 className="mb-0">Frontend</h5>
                          </div>
                          <p>
                            We create a amazing frontend UI using Reactjs, Angular 2+, Vuejs, also we provide limited support to application done in JQuery and AngularJS.
                          </p>
                        </div>
                        <div className="single-feature mb-4">
                          <div className="d-flex align-items-center mb-2">
                            <span className="ti-layout-grid3-alt rounded mr-3 icon icon-color-2"></span>
                            <h5 className="mb-0">Database</h5>
                          </div>
                          <p>
                          We implement from single-node to full production database cluster using RDMS like Oracle, PostgreSQL, MySql and NoSQL like MongoDB, Cassandra, also you can ask for our Search Database expertise advice.
                          </p>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab6-3">
                        <div className="row">
                          <div className="col single-feature mb-4">
                            <div className="d-flex align-items-center mb-2">
                              <span className="ti-bar-chart rounded mr-3 icon icon-color-2"></span>
                              <h5 className="mb-0">Market Data Analysis</h5>
                            </div>
                            <p>
                            Our mathematicians are the right people to do this, using Rlang, Python, and ML to turn Data into valuable information.
                            </p>
                          </div>
                          <div className="col single-feature mb-4">
                            <div className="d-flex align-items-center mb-2">
                              <span className="ti-stats-up rounded mr-3 icon icon-color-3"></span>
                              <h5 className="mb-0">High-frequency trading</h5>
                            </div>
                            <p>
                            For this guys is all about the speed, our HFT team are  the mosts talent C++ Engineer with the
                              ability to match the speed of microseconds, or one-millionth of a second.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab6-4">
                        <p>
                            Security Team is a combination of the right people and a lot of monitoring tools, we take care of the security of the application, Operating System patch, and Networking Security and the right rule implement in a public cloud provider.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Feature;
