import React from "react";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section id="about" className="about-us ptb-100 gray-light-bg">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-7">
                <div className="about-content-left section-heading">
                  <h2>
                    About Us
                  <br />
                    <span>History.</span>
                  </h2>

                  <div className="single-feature mb-4">
                    <div className="icon-box-wrap d-flex align-items-center mb-2">
                      <div className="mr-3 icon-box">
                        <img
                          src="img/image-icon-year.png"
                          alt="icon"
                          className="img-fluid"
                        />
                      </div>
                      <p className="mb-0">
                        We started as a pure Software company in 2019, in the state of New Jersey, USA.
                        in 2020 we decided to open the IT Consultant line of Business
                      </p>
                    </div>
                  </div>
                  <div className="single-feature mb-4">
                    <div className="icon-box-wrap mb-2">
                      <div className="mr-3 icon-box">
                        <img
                          src="img/image-icon-w.png"
                          alt="icon"
                          className="img-fluid"
                        />
                      </div>
                      <p className="mb-0">
                      Before 2019, the Staff has experiences working for a global company, learned
                        the best practice from then and bring this to the customers.
                      </p>
                    </div>
                    <p></p>
                  </div>
                  <div className="single-feature mb-4">
                    <div className="icon-box-wrap mb-2">
                      <div className="mr-3 icon-box">
                        <img
                          src="img/image-icon-it.png"
                          alt="icon"
                          className="img-fluid"
                        />
                      </div>
                      <p className="mb-0">
                      We believe there is not a good IT manager if he never wrote a line of code, our leader
                        has a technology background.
                      </p>
                    </div>
                    <p></p>
                  </div>
                  <div className="single-feature mb-4">
                    <div className="icon-box-wrap mb-2">
                      <div className="mr-3 icon-box">
                        <img
                          src="img/image-icon-sd.png"
                          alt="icon"
                          className="img-fluid"
                        />
                      </div>
                      <p className="mb-0">
                      Prepare to boost your career, join us as Software Developer for an internal project, or as an IT Consultant (Candidate located in the USA).
                      </p>
                    </div>
                    <p></p>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div >
                <img
                    src="img/design_components.svg"
                    alt="about us"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default About;